<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="white--text"> {{ edit ? 'Edit' : 'Upload' }} Report </span>
      </v-card-title>
      <v-card-text class="pt-3 my-4">
        <ac-input label="Name" outlined></ac-input>
        <v-row class="pt-4">
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Select Date"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <ac-button color="error" x-small title="Cancel" @click="menu = false"> </ac-button>
                <ac-button color="primary" x-small title="Okay" @click="$refs.menu.save(date)">
                </ac-button>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-file-input
              small-chips
              hide-details
              outlined
              dense
              hide-details
              label="Upload File"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <ac-button title="Close" color="error" outlined @click="onClose" />
        <ac-button title="Submit" color="success" @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'ViewVideoModal',
  components: {
    AcButton,
    AcInput,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiCalendar,
      },
      date: null,
      menu: false,
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.video {
  max-width: 600px;
}
</style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" Compliance Report ")]),_c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c('ac-select',{staticClass:"company-select me-3",attrs:{"label":"Company","items":_vm.companies},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1)],1)],1),_c('ac-tabs',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"report-configuration",fn:function(){return [_c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol),_c(VCol)],1)],1)],1)]},proxy:true},{key:"previous-reports",fn:function(){return [_c(VCard,[_c('ac-tabs',{attrs:{"tabs":_vm.previousReports.tabs,"vertical":""},scopedSlots:_vm._u([{key:"monthly-compliance-reports",fn:function(){return [_c(VCard,[_c(VCardText,[_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"cols":"2"}},[_c('ac-select',{staticClass:"company-select me-3",attrs:{"label":"Year","items":_vm.monthlyCompliance.years},model:{value:(_vm.monthlyCompliance.selectedYear),callback:function ($$v) {_vm.$set(_vm.monthlyCompliance, "selectedYear", $$v)},expression:"monthlyCompliance.selectedYear"}})],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('ac-button',{attrs:{"title":"Upload Report","icon":_vm.icons.mdiUpload},on:{"click":_vm.showUploadModal}})],1)],1)],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.monthlyCompliance.tableHeader,"items":_vm.monthlyCompliance.tableData,"loading":_vm.monthlyCompliance.loading},scopedSlots:_vm._u([{key:"item.uploaded",fn:function(ref){
var item = ref.item;
return [(item.uploaded)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxMarkedCircleOutline)+" ")]):_c(VIcon,{attrs:{"color":"secondaryy"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMinusCircleOutline)+" ")])]}},{key:"item.clientSent",fn:function(ref){
var item = ref.item;
return [_c(VSimpleCheckbox,{attrs:{"color":"primary","dense":"","hide-details":"","ripple":false},model:{value:(item.clientSent),callback:function ($$v) {_vm.$set(item, "clientSent", $$v)},expression:"item.clientSent"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"Edit","icon":_vm.icons.mdiPencil,"color":"primary"},on:{"click":_vm.showEditModal}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"Download","icon":_vm.icons.mdiDownload,"color":"info","href":item.link,"target":"_blank"}}),_c('ac-icon-btn',{attrs:{"text":"Delete","icon":_vm.icons.mdiDeleteOutline,"color":"error"}})]}}],null,true)})],1)]},proxy:true},{key:"version-logs",fn:function(){return [_c(VCard,[_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.versionLogs.tableHeader,"items":_vm.versionLogs.tableData,"loading":_vm.versionLogs.loading}})],1)]},proxy:true}])})],1),_c('upload-report-modal',{attrs:{"edit":_vm.monthlyCompliance.editReport},model:{value:(_vm.monthlyCompliance.showUpload),callback:function ($$v) {_vm.$set(_vm.monthlyCompliance, "showUpload", $$v)},expression:"monthlyCompliance.showUpload"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
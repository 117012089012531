<template>
  <div>
    <ac-module-heading> Compliance Report </ac-module-heading>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="2">
            <ac-select
              label="Company"
              v-model="selectedCompany"
              :items="companies"
              class="company-select me-3"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <ac-tabs :tabs="tabs">
        <template #report-configuration>
          <v-card>
            <v-card-title>
              <v-row>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </template>
        <template #previous-reports>
          <v-card>
            <ac-tabs :tabs="previousReports.tabs" vertical>
              <template #monthly-compliance-reports>
                <v-card>
                  <v-card-text>
                    <v-row justify="space-between">
                      <v-col cols="2">
                        <ac-select
                          label="Year"
                          v-model="monthlyCompliance.selectedYear"
                          :items="monthlyCompliance.years"
                          class="company-select me-3"
                        />
                      </v-col>
                      <v-col cols="auto">
                        <ac-button
                          title="Upload Report"
                          :icon="icons.mdiUpload"
                          @click="showUploadModal"
                        ></ac-button>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-data-table
                    :headers="monthlyCompliance.tableHeader"
                    :items="monthlyCompliance.tableData"
                    :loading="monthlyCompliance.loading"
                    class="text-no-wrap"
                  >
                    <template #[`item.uploaded`]="{ item }">
                      <v-icon v-if="item.uploaded" color="success">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                      <v-icon v-else color="secondaryy">
                        {{ icons.mdiMinusCircleOutline }}
                      </v-icon>
                    </template>
                    <template #[`item.clientSent`]="{ item }">
                      <v-simple-checkbox
                        v-model="item.clientSent"
                        color="primary"
                        dense
                        hide-details
                        :ripple="false"
                      >
                      </v-simple-checkbox>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <ac-icon-btn
                        btn-class="me-1"
                        text="Edit"
                        :icon="icons.mdiPencil"
                        color="primary"
                        @click="showEditModal"
                      />
                      <ac-icon-btn
                        btn-class="me-1"
                        text="Download"
                        :icon="icons.mdiDownload"
                        color="info"
                        :href="item.link"
                        target="_blank"
                      />
                      <ac-icon-btn text="Delete" :icon="icons.mdiDeleteOutline" color="error" />
                    </template>
                  </v-data-table>
                </v-card>
              </template>
              <template #version-logs>
                <v-card>
                  <v-data-table
                    :headers="versionLogs.tableHeader"
                    :items="versionLogs.tableData"
                    :loading="versionLogs.loading"
                    class="text-no-wrap"
                  >
                  </v-data-table>
                </v-card>
              </template>
            </ac-tabs>
          </v-card>
          <upload-report-modal
            v-model="monthlyCompliance.showUpload"
            :edit="monthlyCompliance.editReport"
          >
          </upload-report-modal>
        </template>
      </ac-tabs>
    </v-card>
  </div>
</template>

<script>
import AcTabs from '@/components/AcTabs.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcButton from '@/components/AcButton.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import UploadReportModal from '@/components/ComplianceReport/UploadReportModal.vue'

import {
  mdiUpload,
  mdiCheckboxMarkedCircleOutline,
  mdiMinusCircleOutline,
  mdiPencil,
  mdiDownload,
  mdiDeleteOutline,
  mdiEye,
} from '@mdi/js'

export default {
  components: {
    AcTabs,
    AcSelect,
    AcButton,
    UploadReportModal,
    AcIconBtn,
  },
  data: () => ({
    icons: {
      mdiUpload,
      mdiCheckboxMarkedCircleOutline,
      mdiMinusCircleOutline,
      mdiPencil,
      mdiDownload,
      mdiDeleteOutline,
      mdiEye,
    },
    selectedCompany: 'All Companies',
    companies: ['Company 1', 'Company 2', 'Company 3'],
    tabs: [
      { title: 'Report Configuration', content: 'report-configuration' },
      { title: 'Previous Reports', content: 'previous-reports' },
    ],

    // * previous-reports
    previousReports: {
      tabs: [
        { title: 'Monthly Compliance Reports', content: 'monthly-compliance-reports' },
        { title: 'Version Logs', content: 'version-logs' },
      ],
    },
    monthlyCompliance: {
      showUpload: false,
      editReport: false,
      selectedYear: null,
      years: ['All', 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014],
      loading: false,
      tableHeader: [
        { text: 'CREATED DATE', value: 'createdDate' },
        { text: 'REPORT DATE', value: 'reportDate' },
        { text: 'NAME', value: 'name' },
        { text: 'UPLOADED', value: 'uploaded' },
        { text: 'SENT TO CLIENT', value: 'clientSent' },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      tableData: [
        {
          createdDate: '25 May 2022 13:43:23',
          reportDate: '25 May 2022',
          name: 'Lorem ipsum dolor sit.',
          uploaded: true,
          link: 'https://www.sample-videos.com/doc/Sample-doc-file-100kb.doc',
          clientSent: true,
        },
      ],
    },
    versionLogs: {
      showUpload: false,
      selectedYear: null,
      years: ['All', 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014],
      loading: false,
      tableHeader: [
        { text: 'DATE', value: 'date' },
        { text: 'MESSAGE', value: 'message' },
        { text: 'ACTION BY', value: 'name' },
      ],
      tableData: [
        {
          date: '25 May 2022 13:47:02',
          message: 'Report Lorem ipsum dolor sit. was unticked',
          name: 'User',
        },
        {
          date: '02 Jul 2021 12:33:24',
          message: 'Report Lorem ipsum dolor sit. was uploaded',
          name: 'User',
        },
      ],
    },
  }),
  methods: {
    showUploadModal() {
      this.monthlyCompliance.editReport = false
      this.monthlyCompliance.showUpload = true
    },
    showEditModal() {
      this.monthlyCompliance.editReport = true
      this.monthlyCompliance.showUpload = true
    },
  },
}
</script>

<style lang="scss" scoped ModuleHeading></style>
